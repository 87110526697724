import React from 'react'
import dab from './img/dab.jpeg'

const App = () => {
  return(
    <div>
      <h1>Koistine</h1>
      <div><img src={dab} alt="Dab" /></div>
    </div>
  )
}

export default App;
